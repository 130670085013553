<template>
  <b-modal
    id="modal-choose-over-under"
    centered
    hide-footer
    :title="$t('general.underOver')"
  >
    <b-row>
      <b-col md="5">
        <b-button variant="primary" block @click="choose('under')">
          {{ $t("general.under") }}
        </b-button>
      </b-col>
      <b-col class="text-center py-1">{{ $t("general.or") }}</b-col>
      <b-col md="5">
        <b-button variant="danger" block @click="choose('over')">
          {{ $t("general.over") }}
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BRow, BCol, BModal, BButton } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BButton,
  },
  methods: {
    show() {
      this.$bvModal.show("modal-choose-over-under");
    },
    choose(overUnder) {
      this.$bvModal.hide("modal-choose-over-under");
      this.$emit("close", overUnder);
    },
  },
};
</script>