<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <lotto></lotto>

    <b-row class="m-0">
      <b-col class="p-0" cols="12" lg="12">
        <result ref="rangeResult"></result>
      </b-col>
      <b-col class="p-0" cols="12" lg="12">
        <result ref="overUnderResult"></result>
      </b-col>
    </b-row>

    <b-card>
      <validation-observer ref="updateForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          key="updateForm"
          @submit="submit"
          :form="$refs.updateForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data" :initValue="initData">
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1"
                :loading="loading"
                :disabled="invalid"
                v-if="$can('update', 'bet-setting')"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NSingleSelect from "@/components/NSingleSelect";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import Toast from "@/libs/toast";
import Lotto from "@/components/Lotto";
import db from "@/libs/firestore-db";
import Result from "@/components/Result";

const BetSettingRepository = Repository.get("betSetting");
const ResultRepository = Repository.get("result");

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NSingleSelect,
    NAsyncSingleSelect,
    Lotto,
    Result,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        betDuration: 0,
        winPercent: 0,
        minBonus: 0,
        maxBonus: 0,
        percentAddBonus: 0,
        payouts: [],
      },
      initData: {},
      loading: false,
      resultReleaseNo: 0,
    };
  },
  watch: {
    resultReleaseNo(value, oldValue) {
      if (value != oldValue) {
        this.getResults();
      }
    },
  },
  mounted() {
    this.show();
    this.listenFightData();
  },
  methods: {
    listenFightData() {
      db.collection("fight_data")
        .doc(`list_result`)
        .onSnapshot((response) => {
          this.resultReleaseNo = response.get("result_release_no");
        });
    },
    async getResults() {
      let response = await ResultRepository.index();
      if (response?.data?.code == 200) {
        const result = response?.data?.data?.listResult;
        if (result?.overUnderList) {
          this.mapOverUnderResult(result.overUnderList);
        }

        if (result?.digitList) {
          this.mapRangeResult(result.digitList);
        }
      }
    },
    mapOverUnderResult(list) {
      let results = [];
      list.forEach((item) => {
        results.push({
          value: item,
          result: item,
        });
      });
      this.$refs.overUnderResult.mapResult(results);
    },
    mapRangeResult(list) {
      let results = [];
      list.forEach((item) => {
        let result = "";
        switch (item) {
          case "1":
            result = "one";
            break;
          case "2":
            result = "two";
            break;
          case "3":
            result = "three";
            break;
          case "4":
            result = "four";
            break;
          case "5":
            result = "five";
            break;
        }

        results.push({
          value: item,
          result: result,
        });
      });
      this.$refs.rangeResult.mapResult(results);
    },
    show() {
      BetSettingRepository.show().then((response) => {
        const data = response.data.data;
        let initData = {
          betDuration: data.setting.betDuration,
          winPercent: data.setting.winPercent,
          minBonus: data.setting.minBonus,
          maxBonus: data.setting.maxBonus,
          percentAddBonus: data.setting.percentAddBonus,
          payouts: [...data.list],
        };
        data.list.forEach((element) => {
          initData[`${element.selected}Payout`] = element.payoutRatio;
        });

        this.initData = {
          ...initData,
        };
      });
    },
    submit() {
      this.$refs.updateForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          let data = {
            setting: {
              betDuration: this.data.betDuration,
              winPercent: this.data.winPercent,
              minBonus: this.data.minBonus,
              maxBonus: this.data.maxBonus,
              percentAddBonus: this.data.percentAddBonus,
            },
            list: [],
          };

          this.data.payouts.forEach((element) => {
            data.list.push({
              selected: element.selected,
              minNumber: element.minNumber,
              maxNumber: element.maxNumber,
              payoutRatio: this.data[`${element.selected}Payout`],
            });
          });

          BetSettingRepository.update(data)
            .then((response) => {
              Toast.fire({
                icon: "success",
                title: this.$t("alert.saveSuccess"),
              });
            })
            .catch((error) => {
              if (error.response?.data?.message) {
                this.$refs.updateForm.setErrors(error.response?.data?.message);
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  setup() {
    const fields = FormInput.map((value) => {
      return value;
    });

    return { fields };
  },
};
</script>