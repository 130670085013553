<template>
  <b-row class="m-0 result">
    <b-col class="p-0" ref="result">
      <div class="result-table table-responsive">
        <table class="table b-table table-bordered b-table-fixed">
          <tbody>
            <tr v-for="(row, ridx) in rows" :key="`row-${ridx}`">
              <td
                class="text-center"
                v-for="(column, cidx) in row"
                :key="`column-${cidx}`"
              >
                <div class="result" :class="column.result">
                  <span
                    v-if="column.result != 'over' && column.result != 'under'"
                  >
                    {{ column.value }}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      rows: [[], [], [], [], [], []],
      currentRow: 0,
    };
  },
  methods: {
    mapResult(data) {
      let totalRow = 6;
      let currentRow = 0;
      let results = [[], [], [], [], [], []];
      let previousResult = "";
      let lastRow = 7;
      let sameResultCount = 0;
      let lastLimitColumn = 0;
      let reachLastRow = false;
      let changeIndex = 0;
      data.forEach((item, i) => {
        if (i > 0) {
          previousResult = data[i - 1].result;
        }

        if (currentRow == totalRow) {
          currentRow = 0;
        }

        if (item.result != data[changeIndex].result) {
          changeIndex = i;
        }

        if (!previousResult || item.result == previousResult) {
          sameResultCount++;
        } else {
          sameResultCount = 1;
          currentRow = 0;

          if (lastLimitColumn >= results[currentRow].length) {
            if (reachLastRow) {
              lastRow--;
            }
          } else {
            results.forEach((result) => {
              if (result.length < results[currentRow].length) {
                const fillColumn = results[currentRow].length - result.length;
                for (let j = 0; j < fillColumn; j++) {
                  result.push({ result: "" });
                }
              }
            });

            lastRow = 7;
          }
        }

        if (sameResultCount == lastRow) {
          reachLastRow = true;
          if (lastRow > 1) {
            currentRow = lastRow - 2;
          } else {
            currentRow = 0;
          }
          sameResultCount--;
          lastLimitColumn = results[currentRow].length;
        } else {
          reachLastRow = false;
          results.forEach((result) => {
            if (result.length < results[currentRow].length) {
              const fillColumn = results[currentRow].length - result.length;
              for (let j = 0; j < fillColumn; j++) {
                result.push({ result: "" });
              }
            }
          });
        }

        results[currentRow].push({ ...item });

        currentRow++;
      });

      const lengths = results.map((a) => a.length);
      const index = lengths.indexOf(Math.max(...lengths));
      results.forEach((result, i) => {
        if (index != i) {
          let fillColumn = results[index].length - result.length;
          for (let j = 0; j < fillColumn; j++) {
            result.push({ result: "" });
          }
        }
      });
      this.rows = [...results];
      this.currentRow = currentRow;
      this.scrollEnd();
    },
    scrollEnd() {
      const el = this.$refs.result;
      if (el) {
        setTimeout(() => {
          el.firstElementChild.scrollLeft = el.firstElementChild.scrollWidth;
        }, 300);
      }
    },
  },
};
</script>