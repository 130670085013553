export default [
    {
        "value": 1,
        "selected": false,
    },
    {
        "value": 2,
        "selected": false,
    },
    {
        "value": 3,
        "selected": false,
    },
    {
        "value": 4,
        "selected": false,
    },
    {
        "value": 5,
        "selected": false,
    },
    {
        "value": 6,
        "selected": false,
    },
    {
        "value": 7,
        "selected": false,
    },
    {
        "value": 8,
        "selected": false,
    },
    {
        "value": 9,
        "selected": false,
    },
    {
        "value": 10,
        "selected": false,
    },
    {
        "value": 11,
        "selected": false,
    },
    {
        "value": 12,
        "selected": false,
    },
    {
        "value": 13,
        "selected": false,
    },
    {
        "value": 14,
        "selected": false,
    },
    {
        "value": 15,
        "selected": false,
    },
    {
        "value": 16,
        "selected": false,
    },
    {
        "value": 17,
        "selected": false,
    },
    {
        "value": 18,
        "selected": false,
    },
    {
        "value": 19,
        "selected": false,
    },
    {
        "value": 20,
        "selected": false,
    },
    {
        "value": 21,
        "selected": false,
    },
    {
        "value": 22,
        "selected": false,
    },
    {
        "value": 23,
        "selected": false,
    },
    {
        "value": 24,
        "selected": false,
    },
    {
        "value": 25,
        "selected": false,
    },
    {
        "value": 26,
        "selected": false,
    },
    {
        "value": 27,
        "selected": false,
    },
    {
        "value": 28,
        "selected": false,
    },
    {
        "value": 29,
        "selected": false,
    },
    {
        "value": 30,
        "selected": false,
    },
    {
        "value": 31,
        "selected": false,
    },
    {
        "value": 32,
        "selected": false,
    },
    {
        "value": 33,
        "selected": false,
    },
    {
        "value": 34,
        "selected": false,
    },
    {
        "value": 35,
        "selected": false,
    },
    {
        "value": 36,
        "selected": false,
    },
    {
        "value": 37,
        "selected": false,
    },
    {
        "value": 38,
        "selected": false,
    },
    {
        "value": 39,
        "selected": false,
    },
    {
        "value": 40,
        "selected": false,
    },
    {
        "value": 41,
        "selected": false,
    },
    {
        "value": 42,
        "selected": false,
    },
    {
        "value": 43,
        "selected": false,
    },
    {
        "value": 44,
        "selected": false,
    },
    {
        "value": 45,
        "selected": false,
    },
    {
        "value": 46,
        "selected": false,
    },
    {
        "value": 47,
        "selected": false,
    },
    {
        "value": 48,
        "selected": false,
    },
    {
        "value": 49,
        "selected": false,
    },
    {
        "value": 50,
        "selected": false,
    },
    {
        "value": 51,
        "selected": false,
    },
    {
        "value": 52,
        "selected": false,
    },
    {
        "value": 53,
        "selected": false,
    },
    {
        "value": 54,
        "selected": false,
    },
    {
        "value": 55,
        "selected": false,
    },
    {
        "value": 56,
        "selected": false,
    },
    {
        "value": 57,
        "selected": false,
    },
    {
        "value": 58,
        "selected": false,
    },
    {
        "value": 59,
        "selected": false,
    },
    {
        "value": 60,
        "selected": false,
    },
    {
        "value": 61,
        "selected": false,
    },
    {
        "value": 62,
        "selected": false,
    },
    {
        "value": 63,
        "selected": false,
    },
    {
        "value": 64,
        "selected": false,
    },
    {
        "value": 65,
        "selected": false,
    },
    {
        "value": 66,
        "selected": false,
    },
    {
        "value": 67,
        "selected": false,
    },
    {
        "value": 68,
        "selected": false,
    },
    {
        "value": 69,
        "selected": false,
    },
    {
        "value": 70,
        "selected": false,
    },
    {
        "value": 71,
        "selected": false,
    },
    {
        "value": 72,
        "selected": false,
    },
    {
        "value": 73,
        "selected": false,
    },
    {
        "value": 74,
        "selected": false,
    },
    {
        "value": 75,
        "selected": false,
    },
    {
        "value": 76,
        "selected": false,
    },
    {
        "value": 77,
        "selected": false,
    },
    {
        "value": 78,
        "selected": false,
    },
    {
        "value": 79,
        "selected": false,
    },
    {
        "value": 80,
        "selected": false,
    },
];