<template>
  <div>
    <b-row class="m-0">
      <b-col cols="12" md="5" class="p-0">
        <div class="previous-match">
          <b-row class="m-0">
            <b-col class="p-0 text-center" align-self="center">
              <span>#{{ pre_no }}</span>
            </b-col>
          </b-row>
          <b-row class="m-0">
            <b-col class="p-0" align-self="center">
              <span class="pl-2 text-capitalize"
                >{{ $t("general.total") }}:</span
              >
            </b-col>
            <b-col class="p-0 text-right" align-self="center">
              <span class="pr-2 text-captitalize"> {{ pre_result }} </span>
            </b-col>
          </b-row>
        </div>
        <b-table-simple class="lotto-table" bordered>
          <b-tbody>
            <b-tr v-for="(row, ridx) in results" :key="`row-${ridx}`">
              <b-td
                class="text-center"
                :class="{ active: column.selected }"
                v-for="(column, cidx) in row"
                :key="`column-${cidx}`"
              >
                {{ column.value }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-col cols="12" md="7" class="p-0">
        <b-row class="m-0 current-match">
          <b-col cols="3" class="p-0 countdown text-center" align-self="center">
            <div>
              <b-spinner
                v-if="timer <= 7"
                variant="danger"
                type="grow"
                label="Spinning"
                small
              ></b-spinner>
              <b-spinner
                v-else
                variant="light"
                type="grow"
                label="Spinning"
                small
              ></b-spinner>
              <span :class="{ 'text-danger': timer <= 7 }">{{ timer }}</span>
            </div>
          </b-col>
          <b-col class="p-0 match">
            <b-row class="m-0">
              <b-col class="p-0 text-center" align-self="center">
                <span>#{{ no }}</span>
              </b-col>
            </b-row>
            <b-row class="m-0">
              <b-col class="p-0 text-center" align-self="center">
                <span class="text-capitalize">{{
                  $t("general.underOver")
                }}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="overunder-table m-0">
          <b-col cols="3" class="p-0">
            <div class="result-box" v-if="timer == 0">
              <div class="random-result">
                <b-badge class="ball" pill variant="primary" v-if="result_no">
                  {{ result_no }}
                </b-badge>
                <h1>
                  <span v-if="isRandom"> {{ result_number }} </span>
                  <span v-else> {{ number }} </span>
                </h1>
              </div>
              <div class="total-result">
                <h3>{{ result }}</h3>
              </div>
            </div>
            <div class="result-box coinbg" v-else></div>
          </b-col>
          <b-col
            class="under p-0"
            :class="{
              active:
                (isShowResult && result >= 210 && result <= 810) ||
                draft_result == 'under',
            }"
          >
            <div class="overunder-header">
              <h1 class="text-center text-capitalize">
                {{ $t("general.under") }}
              </h1>
            </div>
            <div class="overunder-ratio text-center text-primary">
              <span>{{ $t(`rangeBet.under`) }}</span>
              <span class="payout">
                {{ under_payout }}
                <span v-if="under_bonus > 0"> + {{ under_bonus }}</span>
              </span>
              <span>{{ under_amount_bet | currency(ccy) }}</span>
            </div>
          </b-col>
          <b-col
            class="over p-0"
            :class="{
              active:
                (isShowResult && result >= 811 && result <= 1410) ||
                draft_result == 'over',
            }"
          >
            <div class="overunder-header">
              <h1 class="text-center text-capitalize">
                {{ $t("general.over") }}
              </h1>
            </div>
            <div class="overunder-ratio text-center text-danger">
              <span>{{ $t(`rangeBet.over`) }}</span>
              <span class="payout">
                {{ over_payout }}
                <span v-if="over_bonus > 0"> + {{ over_bonus }}</span>
              </span>
              <span>{{ over_amount_bet | currency(ccy) }}</span>
            </div>
          </b-col>
        </b-row>
        <b-row class="m-0">
          <b-col class="p-0 text-center range-number">
            {{ $t("general.rangeNumber") }}
            <span v-if="bonus" class="pl-1"> +{{ bonus }}</span>
          </b-col>
        </b-row>
        <b-row class="range-table m-0">
          <b-col
            class="p-0 one"
            :class="{
              active:
                (isShowResult && result >= 210 && result <= 695) ||
                draft_result_digit == 1,
            }"
          >
            <div class="range-header" @click="confirmResult(1, 'under')">
              <h1 class="text-center text-capitalize">1</h1>
            </div>
            <div class="range-ratio text-center">
              <span class="range">{{ $t(`rangeBet.1`) }}</span>
              <span class="payout">
                {{ one_payout }}
                <span v-if="one_bonus > 0"> + {{ one_bonus }}</span>
              </span>
              <span>{{ one_amount_bet | currency(ccy) }}</span>
            </div>
          </b-col>
          <b-col
            class="p-0 two"
            :class="{
              active:
                (isShowResult && result >= 696 && result <= 763) ||
                draft_result_digit == 2,
            }"
          >
            <div class="range-header" @click="confirmResult(2, 'under')">
              <h1 class="text-center text-capitalize">2</h1>
            </div>
            <div class="range-ratio text-center">
              <span class="range">{{ $t(`rangeBet.2`) }}</span>
              <span class="payout">
                {{ two_payout }}
                <span v-if="two_bonus > 0"> + {{ two_bonus }}</span>
              </span>
              <span>{{ two_amount_bet | currency(ccy) }}</span>
            </div>
          </b-col>
          <b-col
            class="p-0 three"
            :class="{
              active:
                (isShowResult && result >= 764 && result <= 855) ||
                draft_result_digit == 3,
            }"
          >
            <div class="range-header" @click="chooseOverUnder()">
              <h1 class="text-center text-capitalize">3</h1>
            </div>
            <div class="range-ratio text-center">
              <span class="range">{{ $t(`rangeBet.3`) }}</span>
              <span class="payout">
                {{ three_payout }}
                <span v-if="three_bonus > 0"> + {{ three_bonus }}</span>
              </span>
              <span>{{ three_amount_bet | currency(ccy) }}</span>
            </div>
          </b-col>
          <b-col
            class="p-0 four"
            :class="{
              active:
                (isShowResult && result >= 856 && result <= 923) ||
                draft_result_digit == 4,
            }"
          >
            <div class="range-header" @click="confirmResult(4, 'over')">
              <h1 class="text-center text-capitalize">4</h1>
            </div>
            <div class="range-ratio text-center">
              <span class="range">{{ $t(`rangeBet.4`) }}</span>
              <span class="payout">
                {{ four_payout }}
                <span v-if="four_bonus > 0"> + {{ four_bonus }}</span>
              </span>
              <span>{{ four_amount_bet | currency(ccy) }}</span>
            </div>
          </b-col>
          <b-col
            class="p-0 five"
            :class="{
              active:
                (isShowResult && result >= 924 && result <= 1410) ||
                draft_result_digit == 5,
            }"
          >
            <div class="range-header" @click="confirmResult(5, 'over')">
              <h1 class="text-center text-capitalize">5</h1>
            </div>
            <div class="range-ratio text-center">
              <span class="range">{{ $t(`rangeBet.5`) }}</span>
              <span class="payout">
                {{ five_payout }}
                <span v-if="five_bonus > 0"> + {{ five_bonus }}</span>
              </span>
              <span>{{ five_amount_bet | currency(ccy) }}</span>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <choose-over-under-modal
      ref="chooseOverUnder"
      @close="confirmOverUnder"
    ></choose-over-under-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BTableSimple,
  BTbody,
  BTr,
  BTd,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import LottoResult from "@/data/lottoResult";
import db from "@/libs/firestore-db";
import Repository from "@/repositories/RepositoryFactory";
import ChooseOverUnderModal from "./ChooseOverUnderModal";

const BetRepository = Repository.get("bet");

export default {
  components: {
    BRow,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BSpinner,
    BBadge,
    ChooseOverUnderModal,
  },
  data() {
    return {
      ccy: 840,
      kenoData: [...LottoResult],
      results: [],
      pre_result: "",
      pre_no: "",
      no: "",
      timer: "",
      result: "",
      number: "",
      result_number: "",
      result_no: "",
      over_payout: 0,
      over_bonus: 0,
      over_amount_bet: 0,
      under_payout: 0,
      under_bonus: 0,
      under_amount_bet: 0,
      one_payout: 0,
      one_bonus: 0,
      one_amount_bet: 0,
      two_payout: 0,
      two_bonus: 0,
      two_amount_bet: 0,
      three_payout: 0,
      three_bonus: 0,
      three_amount_bet: 0,
      four_payout: 0,
      four_bonus: 0,
      four_amount_bet: 0,
      five_payout: 0,
      five_bonus: 0,
      five_amount_bet: 0,
      bonus: 0,
      draft_result: "",
      draft_result_digit: "",
      isRandom: false,
      isShowResult: false,
      randomHandle: null,
      drop_coin: false,
      coin_drop_timeout: null,
      before_result_sound: new Audio(
        require("@/assets/sounds/before_result.mp3")
      ),
      during_result_sound: new Audio(
        require("@/assets/sounds/during_result.mp3")
      ),
      after_result_sound: new Audio(
        require("@/assets/sounds/after_result.mp3")
      ),
      coin_drop_sound: new Audio(require("@/assets/sounds/coins_drop.wav")),
    };
  },
  watch: {
    timer(value) {
      if (value == 7) {
        this.playBeforeResult();
      } else if (value == 0) {
        this.removeGimmick();
        if (this.coin_drop_sound) {
          this.coin_drop_sound.pause();
          this.coin_drop_sound.currentTime = 0;
        }
      }
    },
    number(value) {
      if (process.browser) {
        if (value >= 1 && value <= 80 && this.timer == 0) {
          this.during_result_sound.play();
          this.selectKeno(value);
        }
      }
    },
    result_no(value) {
      if (value == 20) {
        if (process.browser) {
          setTimeout(() => {
            this.after_result_sound.play();
            this.isShowResult = true;
          }, 800);

          setTimeout(() => {
            this.isShowResult = false;
          }, 4000);
        }
      }
    },
    isRandom(value) {
      if (value) {
        this.random();
      } else {
        clearInterval(this.randomHandle);
      }
    },
    drop_coin(value) {
      if (value) {
        this.gimmick("body");
        this.coin_drop_sound.play();

        let self = this;
        this.coin_drop_timeout = setTimeout(() => {
          self.removeGimmick();
        }, 5000);
      } else {
        this.removeGimmick();
        if (this.coin_drop_sound) {
          this.coin_drop_sound.pause();
          this.coin_drop_sound.currentTime = 0;
        }
      }
    },
  },
  beforeDestroy() {
    if (!this.before_result_sound.paused) {
      this.before_result_sound.pause();
      this.before_result_sound.currentTime = 0;
    }
    if (!this.during_result_sound.paused) {
      this.during_result_sound.pause();
      this.during_result_sound.currentTime = 0;
    }
    if (!this.after_result_sound.paused) {
      this.after_result_sound.pause();
      this.after_result_sound.currentTime = 0;
    }
    if (!this.coin_drop_sound.paused) {
      this.coin_drop_sound.pause();
      this.coin_drop_sound.currentTime = 0;
    }

    this.removeGimmick();
    if (this.coin_drop_timeout) {
      clearTimeout(this.coin_drop_timeout);
    }

    if (this.randomHandle) {
      clearInterval(this.randomHandle);
    }
  },
  created() {
    this.getKenoData();
    this.getFightData();
    this.listenFightData();
  },
  methods: {
    removeGimmick() {
      var exists = document.getElementById("gimmick");
      if (exists) {
        exists.parentNode.removeChild(exists);
      }
    },
    gimmick(el) {
      var exists = document.getElementById("gimmick");
      if (exists) {
        return false;
      }

      var element = document.querySelector(el);
      var canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        focused = false;

      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      canvas.id = "gimmick";

      var coin = new Image();
      coin.src = require("@/assets/images/coins.png");
      // 440 wide, 40 high, 10 states
      coin.onload = function () {
        element.appendChild(canvas);
        focused = true;
        drawloop();
      };
      var coins = [];

      function drawloop() {
        if (focused) {
          requestAnimationFrame(drawloop);
        }

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (Math.random() < 0.3) {
          coins.push({
            x: (Math.random() * canvas.width) | 0,
            y: -50,
            dy: 3,
            s: 0.5 + Math.random(),
            state: (Math.random() * 10) | 0,
          });
        }
        var i = coins.length;
        while (i--) {
          var x = coins[i].x;
          var y = coins[i].y;
          var s = coins[i].s;
          var state = coins[i].state;
          coins[i].state = state > 9 ? 0 : state + 0.1;
          coins[i].dy += 0.3;
          coins[i].y += coins[i].dy;

          ctx.drawImage(
            coin,
            44 * Math.floor(state),
            0,
            44,
            40,
            x,
            y,
            44 * s,
            40 * s
          );

          if (y > canvas.height) {
            coins.splice(i, 1);
          }
        }
      }
    },
    random() {
      let self = this;
      this.randomHandle = setInterval(function () {
        self.result_number = Math.floor(Math.random() * 80) + 1;
      }, 50);
    },
    playBeforeResult() {
      if (process.browser) {
        this.before_result_sound.play();
      }
    },
    listenFightData() {
      db.collection("fight_data")
        .doc(`round`)
        .onSnapshot((response) => {
          this.setFightData(response);
          if (response.get("result_number_random") == "") {
            this.clearKenoData();
          }
        });
    },
    async getFightData() {
      const response = await db.collection("fight_data").doc("round").get();

      let resultRandomNumber = response.get("result_number_random");
      if (resultRandomNumber) {
        resultRandomNumber = resultRandomNumber.split(",");
        resultRandomNumber.forEach((value) => {
          const index = this.kenoData.findIndex((item) => item.value == value);
          if (index !== -1) {
            this.kenoData[index].selected = true;
          }
        });
        this.mapResults(this.kenoData);
      }
    },
    setFightData(response) {
      this.no = response.get("no");
      this.pre_no = response.get("pre_no");
      this.timer = response.get("timer");
      this.result = response.get("result");
      this.number = response.get("number");
      this.pre_result = response.get("pre_result");
      this.result_no = response.get("result_no");
      this.over_payout = response.get("over_payout");
      this.over_bonus = response.get("over_bonus");
      this.over_amount_bet = response.get("over_amount_bet");
      this.under_payout = response.get("under_payout");
      this.under_bonus = response.get("under_bonus");
      this.under_amount_bet = response.get("under_amount_bet");
      this.one_payout = response.get("1_payout");
      this.one_bonus = response.get("1_bonus");
      this.one_amount_bet = response.get("1_amount_bet");
      this.two_payout = response.get("2_payout");
      this.two_bonus = response.get("2_bonus");
      this.two_amount_bet = response.get("2_amount_bet");
      this.three_payout = response.get("3_payout");
      this.three_bonus = response.get("3_bonus");
      this.three_amount_bet = response.get("3_amount_bet");
      this.four_payout = response.get("4_payout");
      this.four_bonus = response.get("4_bonus");
      this.four_amount_bet = response.get("4_amount_bet");
      this.five_payout = response.get("5_payout");
      this.five_bonus = response.get("5_bonus");
      this.five_amount_bet = response.get("5_amount_bet");
      this.bonus = response.get("bonus");
      this.isRandom = response.get("random_number");
      this.drop_coin = response.get("drop_coin");
      this.draft_result = response.get("draft_result");
      this.draft_result_digit = response.get("draft_result_digit");
    },
    getKenoData() {
      this.mapResults(this.kenoData);
    },
    selectKeno(value) {
      const index = this.kenoData.findIndex((item) => item.value == value);
      if (index !== -1) {
        this.kenoData[index].selected = true;
        this.mapResults(this.kenoData);
      }
    },
    clearKenoData() {
      this.kenoData.map((item) => {
        item.selected = false;
      });

      this.mapResults(this.kenoData);
    },
    mapResults(lottoResult) {
      let rows = [];
      const breakRows = [0, 10, 20, 30, 40, 50, 60, 70];

      for (let index = 0; index < lottoResult.length; index++) {
        if (breakRows.includes(index)) {
          rows.push([]);
        }

        rows[rows.length - 1].push({
          ...lottoResult[index],
        });
      }

      this.results = [...rows];
    },
    chooseOverUnder() {
      if (this.timer == 0) {
        return;
      }

      this.$refs.chooseOverUnder.show();
    },
    confirmOverUnder(overUnder) {
      this.confirmResult(3, overUnder);
    },
    confirmResult(number, overUnder) {
      if (this.timer == 0) {
        return;
      }

      this.$bvModal
        .msgBoxConfirm(
          this.$t("alert.setResult", {
            overUnder: this.$t(`general.${overUnder}`),
            digitNumber: number,
          }),
          {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: this.$t("button.yesSaveChanges"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.setResult(number, overUnder);
          }
        });
    },
    setResult(number, overUnder) {
      this.loading = true;
      BetRepository.setResult({
        result: overUnder,
        resultDigit: number,
      })
        .then((response) => {})
        .catch((error) => {})
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>