export default [
  {
    key: 'betDuration',
    label: 'field.betDuration',
    rules: 'required|integer|min_value:0',
    type: 'text',
  },
  {
    key: 'winPercent',
    label: 'field.winPercent',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
  },
  {
    key: 'minBonus',
    label: 'field.minBonus',
    rules: 'required|integer|min_value:0',
    type: 'text',
  },
  {
    key: 'maxBonus',
    label: 'field.maxBonus',
    rules: 'required|integer|min_value:0',
    type: 'text',
  },
  {
    key: 'percentAddBonus',
    label: 'field.bonusDropRate',
    rules: 'required|integer|min_value:0',
    type: 'text',
  },
  {
    key: 'underPayout',
    label: 'field.underPayout',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: "under",
  },
  {
    key: 'overPayout',
    label: 'field.overPayout',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: "over",
  },
  {
    key: '1Payout',
    label: 'field.1Payout',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: "one",
  },
  {
    key: '2Payout',
    label: 'field.2Payout',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: "two",
  },
  {
    key: '3Payout',
    label: 'field.3Payout',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: "three",
  },
  {
    key: '4Payout',
    label: 'field.4Payout',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: "four",
  },
  {
    key: '5Payout',
    label: 'field.5Payout',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: "five",
  }
]
